import { animateCartCountStore } from "../stores/animateCartCountStore.js";
import { showHeaderStore } from "../stores/showHeaderStore.js";

let timeout: number | null = null;
const animationDuration = 750;

export function animateCartCount(): void {
	if (timeout !== null) {
		clearTimeout(timeout);
	}

	showHeaderStore.set(true);
	animateCartCountStore.set(true);

	timeout = window.setTimeout(() => {
		timeout = null;
		animateCartCountStore.set(false);
	}, animationDuration + 1);
}
